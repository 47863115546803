import axios from "axios";
import $ from "jquery";

const addToCart = () => {
  const btns = document.querySelectorAll(".ajax-add-to-cart");
  const popupContainer = document.querySelector(".popup-container");
  const popupContainerInnerContent = popupContainer.querySelector(
    ".inner-content"
  );

  btns.forEach(btn => {
    btn.addEventListener("click", e => {
      e.preventDefault();

      if (btn.classList.contains("disabled")) return false;

      const product_id = btn.getAttribute("data-ajaxid");
      const qty = document.querySelector("input.qty");
      const variation_id = document.querySelector("input.variation_id");

      if (product_id) {
        const fd = new FormData();
        fd.append("product_id", product_id);
        fd.append("action", "ajaxAddToCart");

        if (variation_id) {
          fd.append("variation_id", variation_id.value);
        }

        if (
          qty &&
          qty.value > 1 &&
          document.querySelector("form.cart .inner").contains(btn)
        ) {
          fd.append("quantity", qty.value);
        }

        //popupContainer.classList.add("show");

        axios.post(jsData.ajaxUrl, fd).then(res => {
          if (res.data.success == true) {
            $(document.body).trigger("wc_fragment_refresh");
            setTimeout(() => {
              const event = new Event("show-cart-preview");
              document.body.dispatchEvent(event);
            }, 500);

            // ("Produkt został dodany do koszyka");
            // setTimeout(() => {
            //   if (popupContainer.classList.contains("show")) {
            //     popupContainer.classList.remove("show");
            //     popupContainerInnerContent.textContent = "Proszę czekać...";
            //   }
            // }, 3500);
          }
        });
      }
    });
  });
};

export default addToCart;
