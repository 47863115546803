import $ from "jquery";

$("input.variation_id").on("change", function() {
	var input = $(this),
		inputVal = input.val();

	if (inputVal) {
		var addButton = $(".single-product-add-remove-fav");

		addButton.attr("data-variation-id", input.val());

		var checkProductData = {
			ID: addButton.attr("data-product-id"),
			productType: addButton.attr("data-type"),
			variationID: inputVal,
		};

		loadVariationGallery(inputVal);
	}
});

function loadVariationGallery(variationID) {
	var galleryContainer = $(".products-images-gallery");
	var thumbnails = $(".products-thumbnails");
	var data = {
		action: "woocommerce_get_variation_gallery",
		variation_id: variationID,
	};
	galleryContainer.addClass("loading");
	thumbnails.empty();

	$.post(jsData.ajaxUrl, data, function(response) {
		response = JSON.parse(response);
		console.log(thumbnails);
		thumbnails.html(response.galleryHTML);
		thumbnails.find("a").fancybox();
		galleryContainer.removeClass("loading");
	});
}

$("input.variation_id").trigger("change");
