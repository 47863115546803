import { getCoords } from "./helper";
export default class SiteHeader {
	constructor(
		defaultSettings = {
			setBodyPaddingTop: false,
			stickyHeader: false,
		}
	) {
		this.defaultSettings = defaultSettings;
		this.siteHeader = document.getElementById("masthead");
		this.init();
	}

	init() {
		this.defaultSettings.setBodyPaddingTop === false ||
			this.setBodyPaddingTop();
		this.defaultSettings.stickyHeader === false || this.stickyHeader();
	}

	setBodyPaddingTop() {
		let $body = document.querySelector("body"),
			siteHeaderHeight = this.siteHeader.clientHeight;

		$body.style.paddingTop = `${siteHeaderHeight}px`;
	}

	stickyHeader() {
		window.addEventListener("scroll", () => {
			let headerOffsetTop = getCoords(this.siteHeader).top;

			if (headerOffsetTop > 0) this.siteHeader.classList.add("sticky");
			else this.siteHeader.classList.remove("sticky");
		});
	}
}
